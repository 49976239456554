import { BookOpenIcon } from '@heroicons/react/solid';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

export const Subjects: FC = () => (
  <div className="mt-24">
    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
      <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
        <div>
          <div>
            <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-purple-600 to-indigo-600">
              <BookOpenIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </span>
          </div>
          <div className="mt-6">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
              Matières disponibles à la formation
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              Ayant eu un parcours généraliste, et étant passioné par l'ensemble des techonlogies liées à l'informatique, je concois beaucoup de projets dans des technologies différentes, et accumule un savoir que je suis capable de partager.
            </p>
            <p className="mt-4 text-lg text-gray-500">
              Mes compétences se tournent principalement envers l'administration de système, mais également envers la programmation.
            </p>
            <div className="mt-6">
              <Link
                to="/formations/matieres"
                className="inline-flex bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700"
              >
                Liste complète des matières
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
        <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
          <img
            className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
            src="https://images.unsplash.com/photo-1573164713988-8665fc963095?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2369&q=80"
            alt="Customer profile user interface"
          />
        </div>
      </div>
    </div>
  </div>
);
