import { FC } from 'react';

import {
  AdjustmentsIcon,
  CalendarIcon,
  RefreshIcon,
  ShieldCheckIcon,
} from '@heroicons/react/outline';

const features = [
  {
    name: 'Correctifs de sécurités',
    description: 'Certaines applications peuvent devenir obseletes et sont vulnérables. Ces vulnérabilités peuvent être corrigés grâce aux mises à jour',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Toujours testé',
    description: "Avant l'application des mises à jour en production, c'est testé sur une préproduction auquel vous aurez accès.",
    icon: AdjustmentsIcon,
  },
  {
    name: 'Régulièrements effectuées',
    description: "Nous pouvons réaliser un contrat de maintenance pour l'application des mises à jours pour vous offrir plus de sérénité",
    icon: CalendarIcon,
  },
];

export const Updates : FC = () => (
  <div className="relative bg-white py-16 sm:py-24 lg:py-8">
    <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
      <RefreshIcon className="h-32 w-auto text-center text-primary mx-auto" />
      <h2 className="text-base font-semibold tracking-wider text-cyan-600 uppercase">Mises à jour</h2>
      <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
        Garder à jour ses applications la clé de la sécurité
      </p>
      <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
        Devigne Digital vous accompagne dans la mise à jour de vos applications, avec la possibilité d'effectuer des contrôles réguliers pour s'assurer que votre site est à jour.
      </p>
      <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
        Contrairement à ce que l'on peut penser, chaque application dispose de mises à jour possible, pas seulement les applications WordPress ou Prestashop !
      </p>
      <div className="mt-12">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {features.map((feature) => (
            <div key={feature.name} className="pt-6">
              <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                <div className="-mt-6">
                  <div>
                    <span className="inline-flex items-center justify-center p-3 bg-gradient-to-r from-purple-700 to-primary rounded-md shadow-lg">
                      <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                    </span>
                  </div>
                  <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{feature.name}</h3>
                  <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);
