import React from 'react';
import { CalendarIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import { ISubject } from './SubjectList';

type SubjectProps = {
  subject: ISubject,
}

export const Subject = ({ subject }: SubjectProps) => (
  <li
    key={subject.id}
    className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
  >
    <div className="flex-1 flex flex-col p-8">
      <img className="w-32 h-32 flex-shrink-0 mx-auto rounded-full" src={subject.imageURL} alt="" />
      <h3 className="mt-6 text-gray-900 text-sm font-medium">{subject.name}</h3>
      <dl className="mt-1 flex-grow flex flex-col justify-between">
        <dt className="sr-only">Titre</dt>
        <dd className="text-gray-500 text-sm">{subject.description}</dd>
        <dt className="sr-only">Type</dt>
        <dd className="mt-3">
          <span className="px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full">
            {subject.type}
          </span>
        </dd>
      </dl>
    </div>
    <div>
      <div className="-mt-px flex divide-x bg-primary rounded-br-lg rounded-bl-lg">
        <div className="w-0 flex-1 flex">
          <Link
            to="/contact"
            className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm font-medium border border-transparent rounded-bl-lg"
          >
            <CalendarIcon className="w-5 h-5" aria-hidden="true" />
            <span className="ml-3">Demander la disponibilité</span>
          </Link>
        </div>
      </div>
    </div>
  </li>
);
