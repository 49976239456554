import { FC } from 'react';

import {
  AdjustmentsIcon,
  ChatAltIcon,
  PencilIcon,
  TemplateIcon,
} from '@heroicons/react/outline';

const features = [
  {
    name: "Modification du visuel d'extension",
    description: 'Nous pouvons modifier les modules / plugins que vous trouvez à vos couleurs. ',
    icon: PencilIcon,
  },
  {
    name: "Une nouvelle fonctionnalité qui n'existe pas ?",
    description: 'Alors nous pouvons créer un module rien que pour votre site !',
    icon: AdjustmentsIcon,
  },
  {
    name: 'Votre volonté, votre design',
    description: 'Nous vous proposons soit un design, soit vous nous le dessiner :-)',
    icon: ChatAltIcon,
  },
];

export const Integration : FC = () => (
  <div className="relative bg-white py-16 sm:py-24 lg:py-8">
    <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
      <TemplateIcon className="h-32 w-auto text-center text-primary mx-auto" />
      <h2 className="text-base font-semibold tracking-wider text-cyan-600 uppercase">Intégration</h2>
      <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
        Personnalisez votre application
      </p>
      <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
        Effectuer des installations de modules avec un design différent de votre site, peut diminuer l'attractivité de votre site WEB. Nous proposons un accompagnement pour rendre les modules dont vous disposez, plus en accord avec votre site.
      </p>
      <div className="mt-12">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {features.map((feature) => (
            <div key={feature.name} className="pt-6">
              <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                <div className="-mt-6">
                  <div>
                    <span className="inline-flex items-center justify-center p-3 bg-gradient-to-r from-purple-700 to-primary rounded-md shadow-lg">
                      <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                    </span>
                  </div>
                  <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{feature.name}</h3>
                  <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);
