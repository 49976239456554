import React, { FC } from 'react';
import { FakeProjects } from './FakeProject';

import photovoltaiquesurmesure from '../../img/portfolio/photovoltaique-sur-mesure.png';
import coursesdevignedigital from '../../img/portfolio/courses-devigne-digital.png';
import ridingzone from '../../img/portfolio/ridingzone.png';
import { Project } from './Project';

export interface IProject {
  id: number
  name: string
  website: string
  image: string
  type: string
}

const projects : IProject[] = [
  {
    id: 1,
    name: 'Photovoltaique-sur-mesure',
    website: 'https://photovoltaique-sur-mesure.fr',
    image: photovoltaiquesurmesure,
    type: 'Développement',
  },
  {
    id: 2,
    name: 'Courses By Devigne-Digital',
    website: 'https://courses.devigne.digital',
    image: coursesdevignedigital,
    type: 'Développement',
  },
  {
    id: 3,
    name: 'Zapping abonnés - Riding Zone',
    website: 'https://zapping.ridingzone.com',
    image: ridingzone,
    type: 'Développement',
  },
];

export const Portfolio: FC = () => (
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-6 mb-6">
    <h1
      className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl text-center"
    >
      Projets réalisés par
      {' '}
      <span className="block text-primary xl:inline">Devigne Digital</span>
    </h1>

    <ul className="grid grid-cols-3 gap-6 flex mt-12">
      {(projects.length === 0) && <FakeProjects />}
      {projects.map((project) => (
        <Project project={project} />
      ))}
    </ul>
  </div>
);

export default null;
