import React, { FC } from 'react';
import { Hero } from './Hero';
import { Subjects } from './Subjects';

export const Formation: FC = () => (
  <main className="lg:relative">
    <div className="relative pt-16 pb-32 overflow-hidden">
      <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100" />
      <Hero />
      <Subjects />
    </div>

  </main>
);

export default null;
