import React, { FC } from 'react';
import { Link } from 'react-router-dom';

export const Quote: FC = () => (
  <div className="relative bg-gray-800">
    <div className="h-56 bg-primary sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
      <img
        className="w-full h-full object-cover"
        src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&blend=6366F1&sat=-100&blend-mode=multiply"
        alt=""
      />
    </div>
    <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
      <div className="md:ml-auto md:w-1/2 md:pl-10">
        <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">Nous sommes là pour vous aider</p>
        <p className="mt-3 text-lg text-gray-300">
          Vous avez un projet ? Avez besoin d'aide pour la maintenance de vos applications ?
        </p>
        <div className="mt-8">
          <div className="inline-flex rounded-md shadow">
            <Link
              to="/contact"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
            >
              Contactez-moi !
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);
