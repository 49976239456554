import { FC } from 'react';

import {
  SupportIcon,
  CodeIcon,
  RefreshIcon,
  ViewGridIcon,
  ShieldCheckIcon,
} from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import { PrestashopIcon } from '../../components/icon/PrestashopIcon';

const supportLinks = [
  {
    name: 'PrestaShop',
    href: '/development/prestashop',
    description:
      'Nous pouvons vous accompagner sur le développement de votre boutique e-commerce',
    icon: PrestashopIcon,
  },
  {
    name: 'Application sur mesure',
    href: '/development/custom',
    description:
      "Vous rêvez d'un outil qui n'existe pas ? Vous avez une idée précise en tête ? Confiez-nous la réalisation de votre application sur-mesure.",
    icon: CodeIcon,
  },
  {
    name: 'Mises à jour',
    href: '/development/updates',
    description:
      "Vous disposez d'un vieux site internet et vous souhaiteriez le mettre à jour pour assurer sa sécurité et "
      + 'bénéficier des dernières fonctionnalités ?',
    icon: RefreshIcon,
  },
  {
    name: 'Intégrations',
    href: '/development/integration',
    description:
      'Vous avez vu un plugin super ou un outil qui vous plait mais ne savez pas le mettre en place ? '
      + 'Pas de panique on est là !',
    icon: ViewGridIcon,
  },
  {
    name: 'Sécurité',
    href: '/development/security',
    description:
      'Nous accompagnons les entreprises pour vérifier et assurer la bonne sécurité de vos services / Applications.',
    icon: ShieldCheckIcon,
  },
];

export const Development : FC = () => (
  <div className="bg-white">
    {/* Header */}
    <div className="relative pb-32 bg-gray-800">
      <div className="absolute inset-0">
        <img
          className="w-full h-full object-cover"
          src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&&sat=-100"
          alt=""
        />
        <div className="absolute inset-0 bg-gray-800 mix-blend-multiply" aria-hidden="true" />
      </div>
      <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl">Développement</h1>
        <p className="mt-6 max-w-3xl text-xl text-gray-300">
          Devigne Digital vous accompagne dans la création de site web, mais aussi dans l'ensemble du cycle de vie de
          votre site, les mises à jours, ainsi que les maintenances.
        </p>
        <p className="mt-6 max-w-3xl text-xl text-gray-300">
          Afin de garantir la qualité des livrables et garantir que votre site internet
          est conforme à vos exigences, nous mettons en place systématiquement,une pré-production pour
          que vous puissiez valider la prestation.
        </p>
      </div>
    </div>

    {/* Overlapping cards */}
    <section
      className="-mt-32 max-w-7xl mx-auto relative pb-32 px-4 sm:px-6 lg:px-8"
      aria-labelledby="contact-heading"
    >
      <h2 className="sr-only" id="contact-heading">
        Contact us
      </h2>
      <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
        {supportLinks.map((link) => (
          <div key={link.name} className="flex flex-col bg-white rounded-2xl shadow-xl mb-16">
            <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
              <div className="absolute top-0 p-5 inline-block bg-indigo-600 rounded-xl shadow-lg transform -translate-y-1/2">
                <link.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <h3 className="text-xl font-medium text-gray-900">{link.name}</h3>
              <p className="mt-4 text-base text-gray-500">{link.description}</p>
            </div>
            <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
              <Link to={link.href} className="text-base font-medium text-indigo-700 hover:text-indigo-600">
                En savoir plus
                {' '}
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        ))}
        <div className="flex flex-col bg-white rounded-2xl shadow-xl mb-16">
          <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
            <div className="absolute top-0 p-5 inline-block bg-indigo-600 rounded-xl shadow-lg transform -translate-y-1/2">
              <SupportIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </div>
            <h3 className="text-xl font-medium text-gray-900">Corrections de bugs</h3>
            <p className="mt-4 text-base text-gray-500">
              Nous proposons également la correction de bugs
              sur vos applications actuelles. Contactez-nous directement pour une prise en charge rapide !
            </p>
          </div>
          <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
            <Link to="/contact" className="text-base font-medium text-indigo-700 hover:text-indigo-600">
              En savoir plus
              {' '}
              <span aria-hidden="true"> &rarr;</span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  </div>
);
