import {
  CodeIcon,
  GlobeAltIcon,
  CreditCardIcon,
  DeviceMobileIcon,
} from '@heroicons/react/outline';
import React, { FC } from 'react';
import { PrestashopIcon } from '../../components/icon/PrestashopIcon';
import PrestashopScreenshot from '../../img/development/prestashop-screenshot.png';

const features = [
  {
    name: "Le CMS le plus populaire de l'e-commerce",
    description:
      'Le shop e-commerce open-source le plus populaire en 2020 et Francais !.',
    icon: GlobeAltIcon,
  },
  {
    name: 'Moyens de paiments souples',
    description:
      'La solution vous permettra une grande souplesse des moyens de paiments utilisables ( carte bleu, Paypal, plusieurs fois sans frais etc.. )',
    icon: CreditCardIcon,
  },
  {
    name: 'Personnalisble',
    description:
      'Prestashop est très personnalisable, grâce à ses "modules" et grâce à des thèmes réalisé sur-mesure en fonction de votre image.',
    icon: CodeIcon,
  },
  {
    name: 'Compatible mobiles',
    description:
      'Les thèmes concues sur-mesure ainsi que les modules par Devigne Digital, sont entièrements compatibles mobile, tablette etc..',
    icon: DeviceMobileIcon,
  },
];

export const Prestashop : FC = () => (
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-6 mb-6">
    <div className="py-12 bg-white text-center">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <PrestashopIcon className="w-32 inline" />
          <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Prestashop</h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Un site web pour accroitre vos revenus
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            Avoir un site web Prestashop va vous permettre de vendre vos produits sur l'ensemble des térritoires ou vous
            souhaitez être présents pour vos clients.
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-gradient-to-r from-purple-700 to-primary rounded-md shadow-lg">
                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{feature.name}</h3>
                    <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>

    <img src={PrestashopScreenshot} alt="Prestashop demo website screenshot" />
  </div>
);

export default null;
