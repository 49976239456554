import React, { FC } from 'react';
import { Hero } from './Hero';
import { Customers } from './Customers';
import { Quote } from '../../components/Quote';

export const Homepage: FC = () => (
  <main>
    <Hero />
    <Customers />
    <Quote />
  </main>
);

export default null;
