import { FC } from 'react';

import {
  AdjustmentsIcon, BeakerIcon, ChipIcon,
  CogIcon,
  RefreshIcon,
  ShieldCheckIcon,
} from '@heroicons/react/outline';
import { CodeIcon } from '@heroicons/react/solid';

const features = [
  {
    name: 'Code optimisé',
    description: 'Dans une démarche de qualité, la performance de votre application, ainsi que la qualité du code sont testées automatiquement.',
    icon: ChipIcon,
  },
  {
    name: 'Technologies adaptées',
    description: 'La culture technophile de Devigne Digital aura un véritable impact sur la conception et la pérrénité de vos solutions.',
    icon: AdjustmentsIcon,
  },
  {
    name: 'Mises à jour',
    description: 'Les applications sur sur mesure réalisé par Devigne Digital sont régulièrements mis à jour par nos équipes, sans vous interrompre',
    icon: RefreshIcon,
  },
  {
    name: 'Un livrable de qualité garantie',
    description: 'Les applications développées sur mesure sont automatiquement testées afin de garantir la qualité de votre projet.',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Configuration',
    description:
      'Configurez sur votre application, seulement ce que vous souhaitez rendre configurable.',
    icon: CogIcon,
  },
  {
    name: 'Veille permanente',
    description: 'Nous réalisons en permanence, une veille technologique sur vos applications pour aller plus loins.',
    icon: BeakerIcon,
  },
];

export const CustomApplication : FC = () => (
  <div className="relative bg-white py-16 sm:py-24 lg:py-8">
    <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
      <CodeIcon className="h-32 w-auto text-center text-primary mx-auto" />
      <h2 className="text-base font-semibold tracking-wider text-cyan-600 uppercase">Application sur mesure</h2>
      <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
        Une application, tel que vous l'imaginez
      </p>
      <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
        Devigne Digital vous accompagne dans la création d'une application sur-mesure, en commencant par un site web
        personnalisé jusqu'à des outils, comme un CRM par exemple ou tout autre outil d'automatisation.
      </p>
      <div className="mt-12">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {features.map((feature) => (
            <div key={feature.name} className="pt-6">
              <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                <div className="-mt-6">
                  <div>
                    <span className="inline-flex items-center justify-center p-3 bg-gradient-to-r from-purple-700 to-primary rounded-md shadow-lg">
                      <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                    </span>
                  </div>
                  <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{feature.name}</h3>
                  <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);
