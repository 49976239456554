import { Link } from 'react-router-dom';
import React, { FC } from 'react';

export const Notfound: FC = () => (
  <div className="h-5/6 flex flex-col bg-white">
    <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
      <div className="py-8">
        <div className="text-center">
          <p className="text-sm font-semibold text-primary uppercase tracking-wide">Erreur 404</p>
          <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Page introuvable</h1>
          <p className="mt-2 text-base text-gray-500">Veuillez-nous excuser, mais cette page est introuvable.</p>
          <div className="mt-6">
            <Link to="/" className="text-base font-medium text-primary hover:text-primary">
              Retourner à la page d&apos;accueil
              <span aria-hidden="true">→</span>
            </Link>
          </div>
        </div>
      </div>
    </main>
  </div>
);

export default null;
