import { AcademicCapIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import React, { FC } from 'react';
import cassandraMontierPicture from '../../img/cassandra-montier.png';

export const Hero: FC = () => (
  <div className="relative">
    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
      <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
        <div>
          <div>
            <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-purple-600 to-indigo-600">
              <AcademicCapIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </span>
          </div>
          <div className="mt-6">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
              Laisser vos étudiants se passioner pour l'informatique
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              Avec un total de plus de 400H de formations en informatique, je transmets facilement mon savoir auprès des apprenants, qu'ils soient de niveau similaire et différent,
              je saurais transmettre à vos étudiants, un niveau au delas des attentes attendues.
            </p>
            <div className="mt-6">
              <Link
                to="/contact"
                className="inline-flex bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700"
              >
                Je cherche un formateur en informatique
              </Link>
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-200 pt-6">
          <blockquote>
            <div>
              <p className="text-bs text-gray-500">
                "Henri a réussi à transmettre son expérience à nos étudiants, et les motiver pour réaliser leurs projets et à se surpasser."
              </p>
            </div>
            <footer className="mt-3">
              <div className="flex items-center space-x-3">
                <div className="flex-shrink-0">
                  <img
                    className="h-6 w-6 rounded-full"
                    src={cassandraMontierPicture}
                    alt="Cassandra Montier"
                  />
                </div>
                <div className="text-base font-medium text-gray-700">
                  Cassandra Montier
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
      <div className="mt-12 sm:mt-16 lg:mt-0">
        <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
          <img
            className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
            src="https://images.unsplash.com/photo-1531538606174-0f90ff5dce83?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2274&q=80"
            alt="Inbox user interface"
          />
        </div>
      </div>
    </div>
  </div>
);

export default null;
