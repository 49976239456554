import React from 'react';
import 'tailwindcss/tailwind.css';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Header } from './components/Header';
import { Homepage } from './routes/Homepage/Homepage';
import { Formation } from './routes/Formation/Formation';
import { SubjectList } from './routes/Formation/SubjectList/SubjectList';
import { Notfound } from './routes/Notfound';
import { Footer } from './components/Footer';
import { Contact } from './routes/Contact/Contact';
import { Portfolio } from './routes/Portfolio/Portfolio';
import { Development } from './routes/Development/Development';
import { Prestashop } from './routes/Development/Prestashop';
import { CustomApplication } from './routes/Development/CustomApplication';
import { Updates } from './routes/Development/Updates';
import { Integration } from './routes/Development/Integration';
import { Security } from './routes/Development/Security';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <div>
          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route exact path="/development" component={Development} />
            <Route exact path="/development/prestashop" component={Prestashop} />
            <Route exact path="/development/custom" component={CustomApplication} />
            <Route exact path="/development/updates" component={Updates} />
            <Route exact path="/development/integration" component={Integration} />
            <Route exact path="/development/security" component={Security} />
            <Route exact path="/formations" component={Formation} />
            <Route exact path="/formations/matieres" component={SubjectList} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/portfolio" component={Portfolio} />
            <Route path="*">
              <Notfound />
            </Route>
          </Switch>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
