import React, { FC } from 'react';

const year = (new Date()).getFullYear();

export const Footer: FC = () => (
  <footer className="bg-primary text-white">
    <div className="max-w-7xl mx-auto py-4 px-4 overflow-hidden sm:px-6 lg:px-8 ">
      <p className="text-center text-base">
        &copy;
        {year}
        {' '}
        Devigne Digital, Tous droits réservés
      </p>
    </div>
  </footer>
);
