import React, { FC, Fragment } from 'react';

import { Popover, Transition } from '@headlessui/react';
import {
  CodeIcon,
  MenuIcon,
  QuestionMarkCircleIcon,
  RefreshIcon,
  ShieldCheckIcon,
  XIcon,
} from '@heroicons/react/outline';
import { ChevronDownIcon, TemplateIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import { PrestashopIcon } from './icon/PrestashopIcon';

import mainLogo from '../img/logo.png';

const features = [
  {
    name: 'PrestaShop',
    href: '/development/prestashop',
    description: 'Conception de site web e-commerce avec PrestaShop.',
    icon: PrestashopIcon,
  },
  {
    name: "Création d'applications",
    href: '/development/custom',
    description: "Conception et créations d'applications sur-mesures",
    icon: CodeIcon,
  },
  {
    name: 'Securité',
    href: '/development/security',
    description: 'Sécurisation de vos applications et CMS après audit.',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Integrations',
    href: '/development/integration',
    description: 'Mise en place du visuel de votre site internet.',
    icon: TemplateIcon,
  },
  {
    name: 'Mises à jour',
    href: '/development/updates',
    description: 'Mise à jour du code de vos Applications ( Symfony, Prestashop, WordPress )',
    icon: RefreshIcon,
  },
];

// @ts-ignore
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const Header: FC = () => (
  <div className="relative bg-gray-50">
    <Popover className="relative bg-white shadow">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <span className="sr-only">Devigne Digital</span>
              <img
                className="h-8 w-auto sm:h-10"
                src={mainLogo}
                alt="Devigne-Digital Logo"
              />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900' : 'text-gray-500',
                      'pl-1group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary',
                    )}
                  >
                    <span>Développement</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:text-gray-500',
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute -ml-4 mt-3 transform z-10 px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {features.map((item) => (
                            <Popover.Button
                              as={Link}
                              key={item.name}
                              to={item.href}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                            >
                              <item.icon className="flex-shrink-0 h-6 w-6 text-primary" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">{item.name}</p>
                                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                              </div>
                            </Popover.Button>
                          ))}
                        </div>
                        <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                          <div className="flow-root">
                            <Popover.Button
                              as={Link}
                              to="/contact"
                              className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
                            >
                              <QuestionMarkCircleIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                              <span className="ml-3">Vous voulez nous parler  de votre projet ?</span>
                            </Popover.Button>
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>

            <Popover.Button as={Link} to="/formations" className="text-base font-medium text-gray-500 hover:text-gray-900">
              Formations
            </Popover.Button>
            <Popover.Button as={Link} to="/contact" className="text-base font-medium text-gray-500 hover:text-gray-900">
              Contact
            </Popover.Button>
          </Popover.Group>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0" />
        </div>
      </div>

      <Transition
        as={Fragment}
      >
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 z-10 transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-4">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src={mainLogo}
                    alt="Devigne-Digital"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <Popover.Button>
                  <nav className="grid gap-y-8">
                    {features.map((item) => (
                      <Popover.Button
                        as={Link}
                        key={item.name}
                        to={item.href}
                        className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                      >
                        <item.icon className="flex-shrink-0 h-6 w-6 text-primary" aria-hidden="true" />
                        <span className="ml-3 text-base font-medium text-gray-900">{item.name}</span>
                      </Popover.Button>
                    ))}
                  </nav>
                </Popover.Button>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <Popover.Button as={Link} to="/formations" className="text-base font-medium text-gray-900 hover:text-gray-700 block">
                Formations
              </Popover.Button>
              <Popover.Button as={Link} to="/contact" className="text-base font-medium text-gray-900 hover:text-gray-700 block">
                Contact
              </Popover.Button>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  </div>
);

export default null;
