import React, { FC } from 'react';
import ecoleITLogo from '../../img/customers/ecole-it.svg';
import supinfoLogo from '../../img/customers/supinfo.png';
import wapitiLogo from '../../img/customers/wapiti.png';
import puzzleMediaLogo from '../../img/customers/puzzle-media.png';
import epsiLogo from '../../img/customers/epsi.svg';

export const Customers: FC = () => (
  <div className="bg-gray-100 filter-gray">
    <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <p className="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
        Une confiance entière par des entreprises de toutes tailles.
      </p>
      <div className="mt-6 grid grid-cols-2 gap-8 md:grid-cols-4 lg:grid-cols-5">
        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <a href="https://supinfo.com">
            <img
              className="h-24"
              src={supinfoLogo}
              alt="SUPINFO International University"
            />
          </a>
        </div>
        <div className="col-span-1 flex justify-center md:col-span-2 md:col-start-2 lg:col-span-1">
          <a href="https://wapiti-agency.com">
            <img
              className="h-24"
              src={wapitiLogo}
              alt="Wapiti-Agency"
            />
          </a>
        </div>
        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <a href="https://ecole-it.com">
            <img
              className="h-24"
              src={ecoleITLogo}
              alt="Ecole-IT"
            />
          </a>
        </div>
        <div className="col-span-1 flex justify-center md:col-span-2 md:col-start-4 lg:col-span-1">
          <a href="https://www.puzzlemedia.fr">
            <img
              className="h-24"
              src={puzzleMediaLogo}
              alt="Puzzle Media"
            />
          </a>
        </div>
        <div className="col-span-1 flex justify-center md:col-span-2 md:col-start-4 lg:col-span-1">
          <a href="https://www.epsi.fr">
            <img
              className="h-24"
              src={epsiLogo}
              alt="Epsi"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default null;
