import React from 'react';
import { IProject } from './Portfolio';

type ProjectProps = {
  project: IProject,
}

export const Project = ({ project }: ProjectProps) => (
  <li
    className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
  >
    <div className="flex-1 flex flex-col p-8">
      <div className="mx-auto flex-shrink-0"><img className="block h-48" src={project.image} alt={project.name} /></div>
      <dl className="mt-6 flex-grow flex flex-col justify-between">
        <dt className="sr-only">Titre</dt>
        <dd className="text-medium font-bold">{project.name}</dd>
        <dt className="sr-only">Type</dt>
        <dd className="mt-3">
          <span className="px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full p-1.5 w-full">Développement</span>
        </dd>
      </dl>
    </div>
    <div>
      <div className="-mt-px flex divide-x bg-primary rounded-br-lg rounded-bl-lg">
        <div className="w-0 flex-1 flex">
          <a
            href={project.website}
            className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm font-medium border border-transparent rounded-bl-lg"
          >
            <span className="ml-3 "> </span>
            Voir le projet
          </a>
        </div>
      </div>
    </div>
  </li>
);

export default null;
