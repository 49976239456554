import React, { Component } from 'react';
import { Form } from './Form';
import { ContactInformation } from './ContactInformation';

export class Contact extends Component {
  render() {
    return (
      <div className="bg-gray-100">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="relative bg-white shadow-xl">
            <h2 className="sr-only">Nous contacter</h2>

            <div className="grid grid-cols-1 lg:grid-cols-3">
              <ContactInformation />
              <Form />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
