import React, { FC } from 'react';

export const FakeProject: FC = () => (
  <li
    className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
  >
    <div className="flex-1 flex flex-col p-8">
      <div className="w-full h-32 flex-shrink-0 mx-auto bg-gray-200 animate-pulse"> </div>
      <h3 className="mt-6 text-gray-900 text-sm font-medium bg-gray-200 animate-pulse w-full"> </h3>
      <dl className="mt-1 flex-grow flex flex-col justify-between">
        <dt className="sr-only">Titre</dt>
        <dd className="text-gray-500 text-sm bg-gray-200 animate-pulse w-full p-1.5"> </dd>
        <dt className="sr-only">Type</dt>
        <dd className="mt-3">
          <span className="px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full p-1.5 animate-pulse w-full"> </span>
        </dd>
      </dl>
    </div>
    <div>
      <div className="-mt-px flex divide-x bg-primary rounded-br-lg rounded-bl-lg">
        <div className="w-0 flex-1 flex">
          <a
            href="mailto:"
            className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm font-medium border border-transparent rounded-bl-lg animate-pulse p-1.5 w-full bg-gray-200"
          >
            <span className="ml-3 "> </span>
          </a>
        </div>
      </div>
    </div>
  </li>
);

export const FakeProjects : FC = () => (
  <>
    {[1, 2, 3].map(() => <FakeProject />)}
  </>
);

export default null;
