import React, {
  FC, Fragment, useEffect, useState,
} from 'react';
import { Tab } from '@headlessui/react';
import { Subject } from './Subject';
import { FakeSubject } from './FakeSubject';

export interface ISubject {
  id: number;
  name: string;
  description: string;
  type: string;
  imageURL: string;
}

export const SubjectList: FC = () => {
  const [subjects, setSubjects] = useState<ISubject[]>([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/subjects/`)
      .then((response) => response.json())
      .then((data) => setSubjects(data))
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-6 mb-6">
      <h1
        className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl text-center"
      >
        <span className="block xl:inline">Liste des </span>
        {' '}
        <span className="block text-primary xl:inline">matières</span>
      </h1>

      <div className="mt-12">
        <Tab.Group>
          <Tab.List className="text-center">
            <Tab as={Fragment}>
              {({ selected }) => (
                <button
                  className={
                    selected ? 'btn-primary' : 'btn-light'
                  }
                >
                  Toutes
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button
                  className={
                    selected ? 'btn-primary' : 'btn-light'
                  }
                >
                  Développement
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button
                  className={
                    selected ? 'btn-primary' : 'btn-light'
                  }
                >
                  Administration Systèmes et Réseaux
                </button>
              )}
            </Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              {subjects.length === 0
              && (
              <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-12">
                <FakeSubject />
                <FakeSubject />
                <FakeSubject />
                <FakeSubject />
              </ul>
              )}
              <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-12">
                {subjects.map((subject: ISubject) => (
                  <Subject subject={subject} />
                ))}
              </ul>
            </Tab.Panel>
            <Tab.Panel>
              <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-12">
                {subjects.map((subject) => (
                  subject.type === 'Développement' && <Subject subject={subject} />
                ))}
              </ul>

            </Tab.Panel>
            <Tab.Panel>
              <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-12">
                {subjects.map((subject) => (
                  subject.type === 'Administration système' && <Subject subject={subject} />
                ))}
              </ul>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};
