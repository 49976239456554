import React, { FormEvent, Fragment, useState } from 'react';
import { CheckIcon } from '@heroicons/react/solid';

import { Dialog, Transition } from '@headlessui/react';

export function Form() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  function closeModal() {
    setIsModalOpen(false);
  }

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    const form = event.target as HTMLFormElement;

    fetch(form.action, {
      method: form.method,
      body: new URLSearchParams([...(new FormData(form) as any)]),
    }).then((data) => {
      openModal();
    }).catch((error) => {
      alert(error);
    });
  };

  // @ts-ignore
  return (
    <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl bg-white border-2 border-primary">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Message envoyé
                </Dialog.Title>
                <div className="mt-2">
                  <span className="text-green-400 text-center">
                    <CheckIcon className="h-24 w-24 mx-auto" />
                  </span>
                  <p className="text-sm text-gray-500">
                    Votre message a bien été envoyé !

                    Nous reviendrons vers vous rapidement.
                  </p>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium bg-primary border border-transparent rounded-md hover:bg-primary focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 w-full"
                    onClick={closeModal}
                  >
                    Fermer
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      <h3 className="text-lg font-medium text-gray-900">Formulaire de contact</h3>
      <form action={`${process.env.REACT_APP_API_URL}/contact`} method="POST" onSubmit={handleSubmit} className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
        <div>
          <label htmlFor="first-name" className="block text-sm font-medium text-gray-900">
            Prénom
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="firstName"
              id="first-name"
              autoComplete="given-name"
              value={firstName}
              required
              onChange={(e) => setFirstName(e.target.value)}
              className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary focus:border-primary border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div>
          <label htmlFor="last-name" className="block text-sm font-medium text-gray-900">
            Nom
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="lastName"
              id="last-name"
              autoComplete="family-name"
              value={lastName}
              required
              onChange={(e) => setLastName(e.target.value)}
              className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary focus:border-primary border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-900">
            Adresse email
          </label>
          <div className="mt-1">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
              className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary focus:border-primary border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div>
          <div className="flex justify-between">
            <label htmlFor="phone" className="block text-sm font-medium text-gray-900">
              Téléphone
            </label>
            <span id="phone-optional" className="text-sm text-gray-500">
              Optionnel
            </span>
          </div>
          <div className="mt-1">
            <input
              type="text"
              name="phone"
              id="phone"
              autoComplete="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary focus:border-primary border-gray-300 rounded-md"
              aria-describedby="phone-optional"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label htmlFor="subject" className="block text-sm font-medium text-gray-900">
            Sujet
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="subject"
              id="subject"
              value={subject}
              required
              onChange={(e) => setSubject(e.target.value)}
              className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary focus:border-primary border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <div className="flex justify-between">
            <label htmlFor="message" className="block text-sm font-medium text-gray-900">
              Message
            </label>
          </div>
          <div className="mt-1">
            <textarea
              id="message"
              name="message"
              rows={4}
              className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-primary focus:border-primary border border-gray-300 rounded-md"
              aria-describedby="message-max"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
        </div>
        <div className="sm:col-span-2 sm:flex sm:justify-end">
          <button
            type="submit"
            className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:w-auto"
          >
            Soumettre
          </button>
        </div>
      </form>
    </div>
  );
}
